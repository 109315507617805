<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <h1>Confirm Details</h1>
        <p></p>
        <form
          name="form"
          id="x1"
          method="POST"
          action="https://paynow.netcash.co.za/site/paynow.aspx"
          target="_top"
        >
          <!---Form POST **--->
          <input
            type="text"
            name="M1"
            value="d8e6b97c-e277-4dc3-a91e-bf61bc9d4ec6"
            class="hide-input"
          />
          <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
          <input
            type="text"
            name="M2"
            value="ad94da57-19ae-4935-9598-53e8d809bb88"
            class="hide-input"
          />
          <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
          <v-row>
            <v-col cols="6">
              <label>Payment Reference:</label>
            </v-col>
            <v-col cols="6">
              <!--input type="text" name="p2" value="6346a5422faa502ffa6b56ef"> ---Unique ID for this / each transaction--->
              <input type="text" name="p2" v-model="payment.id" />
              <!---Unique ID for this / each transaction--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Service Rendered:</label>
            </v-col>
            <v-col cols="6">
              <input type="text" name="p3" value="Test / Demo goods" />
              <!---Description of goods being purchased--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Amount:</label>
            </v-col>
            <v-col cols="6" v-if="payment.amount > 0">
              <input type="text" name="p4" v-model="payment.amount" />
              <!---Amount to be settled / paid--->
            </v-col>
            <v-col cols="6" v-else>
              <input type="text" name="p4" :value="30" />
              <!---Amount to be settled / paid--->
            </v-col>
          </v-row>
          <input type="text" name="Budget" value="Y" class="hide-input" />
          <!---Compulsory should be Y--->
          <input type="text" name="m4" value="Extra 1" class="hide-input" />
          <!---This is an extra field ***--->
          <input type="text" name="m5" value="Extra 2" class="hide-input" />
          <!---This is an extra field ***--->
          <input type="text" name="m6" value="Extra 3" class="hide-input" />
          <!---This is an extra field ***--->
          <v-row>
            <v-col cols="6">
              <label>Email Address:</label>
            </v-col>
            <v-col cols="6">
              <input type="text" name="m9" v-model="me.email" />
              <!---Card holders email address--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Cellphone Number:</label>
            </v-col>
            <v-col cols="6">
              <input type="text" name="m11" v-model="me.telNumber" />
              <!---Card holders mobile number--->
            </v-col>
          </v-row>
          <input
            type="text"
            name="m10"
            value="Demo attempt for testing"
            class="hide-input"
          />
          <!---Dataset used by shopping carts i.e. OSCommerce--->
          <input type="text" name="m14" value="1" class="hide-input" />
          <!---Request credit card subscription token 0 = no | 1 = yes--->
          <input
            type="text"
            name="m15"
            value="11ad173c-9d3f-43b3-19se-as7b865e3099"
            class="hide-input"
          />
          <!---Credit card token for subscription cards--->
          <input type="text" name="m16" value="1" class="hide-input" />
          <!---Subscription indicator 0 = no | 1 = yes--->
          <input type="text" name="m17" value="6" class="hide-input" />
          <!---Number of cycles for subscription payment--->
          <input type="text" name="m18" value="1" class="hide-input" />
          <!---Subscription frequency--->
          <v-row>
            <v-col cols="6">
              <label>Subscription start Date:</label>
            </v-col>
            <v-col cols="6">
              <input type="text" name="m19" value="2021-01-01" disabled />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Subcribtion Fee:</label>
            </v-col>
            <v-col cols="6">
              <input type="text" name="m20" value="15.00" disabled />
              <!---Subscription recurring amount--->
            </v-col>
          </v-row>
          <p></p>
          <v-btn
            color="#7D72F5"
            class="app-btn2 tertiary white--text"
            style="margin-left: 30%; margin-right: 50%"
          >
            <v-icon>mdi-cash-multiple</v-icon>
            <input name="submit" type="submit" value="PAY" />
            <!---Submit button--->
          </v-btn>
        </form>
      </v-col>
    </v-row>
    <p></p>
    <v-row>
      <v-col cols="12">
        <p style="margin-left: 10%; margin-right: 10%">
          Please Note that You will be redirected to a payment gateway to
          process your payment
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { User } from "@/models/User";
import { Payment } from "@/models/Payment";
import { gql } from "apollo-boost";

export default {
  components: {},
  name: "Process Payment",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      selectedItem: 1,
      isLoading: false,
      paymentModel: new Payment(),
      userModel: new User(),
      process: [""],
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          telNumber
        }
      }
    `,
    payment: {
      query: gql`
        query Payment($id: ID!) {
          payment(id: $id) {
            id
            employer
            employerName
            employee
            employeeName
            status
            processBy
            period
            amount
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response) {
        // console.log(response)
        id;
        employer;
        employerName;
        employee;
        employeeName;
        status;
        processBy;
        period;
        this.paymentModel.amount = this.response.data.payment.amount;
        console.log(this.paymentModel.amount);
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {},
  computed: {
    filteredCarModel() {
      return this.processModel.filter((pm) => {
        return this.process.includes(pm.name);
      });
    },
  },
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}
.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.forlist.theme--light.v-list {
  background: #fff;
}

.forlist.theme--light.v-list .v-list-item {
  border-bottom: 1px solid #dadada;
}

/*process payment*/
.hide-input {
  display: none;
}
</style>
